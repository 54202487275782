import { createSlice } from "@reduxjs/toolkit";

import { IClient } from "@/modules/client/clients.interface";

export interface IClientStore {
  isLoaded: boolean;
  data: IClient[];
  selectedClient?: number;
}

export const slice = createSlice({
  name: "clients",
  initialState: {
    isLoaded: false,
    data: [],
  } as IClientStore,
  reducers: {
    addClients: (state, action) => ({
      ...state,
      isLoaded: true,
      data: action.payload,
    }),
    addSingleClient: (state, action) => ({
      ...state,
      isLoaded: true,
      data: [...state.data, action.payload],
    }),
    updateSelectedClient: (state, action) => ({
      ...state,
      selectedClient: action.payload,
    }),
    updateAssessments: (state, action) => ({
      ...state,
      data: state.data.map((item: IClient) => {
        return item.clientId !== action.payload.clientId
          ? item
          : {
              ...item,
              assessments: action.payload.assessments,
            };
      }),
    }),
    removeClientUser: (state, action) => {
      return {
        ...state,
        data: state.data.map((client) => {
          if (client.clientId !== action.payload.clientId) return client;
          return {
            ...client,
            clientUsers: client.clientUsers?.filter((clientUser) => clientUser.userId !== action.payload.userId),
          };
        }),
      };
    },
    removeAllClients: () => ({
      isLoaded: false,
      data: [],
    }),
  },
});

export const {
  addClients,
  addSingleClient,
  updateSelectedClient,
  updateAssessments,
  removeAllClients,
  removeClientUser,
} = slice.actions;

export const selectClientData = (state: { clients: IClientStore }) => state.clients.data;
export const selectPickedClient = (state: { clients: { selectedClient: number } }) => {
  return state.clients.selectedClient;
};

export default slice.reducer;
