import { useCallback, useState } from "react";
import { notification } from "antd";
import { ClientHttpService } from "@/modules/client/services/client-http.service";
import { useDispatch } from "react-redux";
import { removeClientUser as removeReduxClientUser } from "@/shared/services/store/clientStore";

export function useRemoveClientUser() {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const removeClientUser = useCallback(
    async (clientId: number, userId: number) => {
      try {
        setLoading(true);
        await ClientHttpService.removeClientUser(clientId, userId);
        dispatch(removeReduxClientUser({ clientId, userId }));
      } catch (e: any) {
        setError(e?.message || "Error while removing user");

        notification.error({
          message: e.message || "Error while removing user",
        });
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [dispatch],
  );

  return { loading, error, removeClientUser };
}
