import React, { ChangeEvent, useContext, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import cn from "classnames";
import { Button, Dropdown, Input, Menu, Row, Space, Table, Tooltip } from "antd";
import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table/Table";
import { ReactComponent as AuditSVG } from "@/assets/icons/feather-icons/activity.svg";
import { ReactComponent as PlusSVG } from "@/assets/icons/feather-icons/plus-16-white.svg";
import { AssessmentInviteStatus } from "@/modules/assessments/scenes/AssessmentInvites/invites.constants";
import { IUser } from "@/modules/client/clients.interface";
import { AssessmentAbilityContext } from "@/shared/contexts/ability.context";
import { AssessmentContext } from "@/shared/contexts/assessment.context";
import TableHeader from "@/shared/components/TableHeader/TableHeader";
import { statusText } from "@/shared/components/candidate/scenes/InviteCard/InviteCard";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { IAssessmentInvite } from "@/shared/hooks/initializer/use-initialize-candidate.hook";
import { DB_USER_KEY } from "@/shared/hooks/auth/currentUser.hook";
import { longFormatDate, shortFormatDate } from "@/shared/utils/formaters/dataFormater/formatDate";
import { formatTableData } from "@/shared/utils/formaters/uiFormaters/tableFormaters";
import { ButtonColorType, ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { REPORT_MENU_ITEM } from "@/routing/AppRouter/routes.constants";

import style from "./style.module.css";

const candidatesInvitesColumnsStatic: ColumnsType<IAssessmentInvite> = [
  {
    key: "candidateFullName",
    title: "Candidate",
    dataIndex: "candidateFullName",
    className: style.tableColumn,
    sorter: true,
    filterIcon: (filtered: boolean) => (
      <Tooltip overlay="Search by name or email">
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      </Tooltip>
    ),
  },
  {
    key: "email",
    title: "Email",
    dataIndex: "email",
    className: style.tableColumn,
    sorter: true,
    render: (value) => <a href={`mailto:${value}`}>{value}</a>,
  },
  {
    key: "status",
    title: "Status",
    dataIndex: "statusName",
    className: style.tableColumn,
    sorter: true,
    render: (value) =>
      value === statusText[AssessmentInviteStatus.Rescinded] ? <span style={{ color: "red" }}>{value}</span> : value,
  },
  {
    key: "createdAt",
    title: "Invited On",
    dataIndex: "createdAt",
    className: style.tableColumn,
    render: (value) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
    sorter: true,
  },
  {
    key: "claimedOn",
    title: "Accepted On",
    dataIndex: "claimedOn",
    className: style.tableColumn,
    render: (value) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
    sorter: true,
  },
  {
    key: "startedOn",
    title: "Started On",
    dataIndex: "startedOn",
    className: style.tableColumn,
    render: (value) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
    sorter: true,
  },
  {
    key: "finishedOn",
    title: "Finished On",
    dataIndex: "finishedOn",
    className: style.tableColumn,
    render: (value) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
    sorter: true,
  },
  {
    key: "invitedBy",
    title: "Invited By",
    dataIndex: "invitedByUser",
    className: style.tableColumn,

    render: (value, { claimedByUser }) => {
      const isSelfRegistered =
        typeof claimedByUser?.userId !== "undefined" &&
        typeof value?.userId !== "undefined" &&
        claimedByUser?.userId === value?.userId;

      return value?.fullName ? (isSelfRegistered ? "Self/QR" : value?.fullName) : null;
    },
  },
];

export function TableCandidates({
  dataList,
  onAddCandidates,
  onSearchChange,
  isSendable,
  openQRModal,
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
  totalCount,
  loading,
  onCandidateSearch,
  onSortChange,
  statuses,
  onFilterStatus,
  filteredStatuses,
  sortOptions,
  onInvitesRescind,
  onInvitesRescore,
  onInvitesDisableTimer,
}: {
  dataList: IAssessmentInvite[];
  onAddCandidates: () => void;
  pageSize: number;
  currentPage: number;
  totalCount: number;
  loading: boolean;
  statuses: string[];
  filteredStatuses: string[];
  isSendable?: boolean;
  onCandidateSearch: (search: string) => void;
  openQRModal?: () => void;
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSortChange: (options: any) => void;
  onFilterStatus: (statuses: string[]) => void;
  setPageSize: (size: number) => void;
  setCurrentPage: (page: number) => void;
  sortOptions: { column?: string; direction?: string };
  onInvitesRescind: (ids: number[]) => Promise<void>;
  onInvitesRescore: (ids: number[]) => void;
  onInvitesDisableTimer: (invites: Array<IAssessmentInvite>) => void;
}) {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { assessment } = useContext(AssessmentContext);
  const currentUser: IUser = JSON.parse(String(localStorage.getItem(DB_USER_KEY)));
  const isAdmin: boolean = currentUser?.isAdmin;
  const isAssessmentArchived = Boolean(assessment?.archivedOn);
  const assessmentAbility = useContext(AssessmentAbilityContext);
  const canManageAssessment = assessmentAbility.can(Action.Manage, Subject.ASSESSMENT);
  const canManageAssessmentInvite = assessmentAbility.can(Action.Manage, Subject.ASSESSMENT_INVITE);
  const canRescoreAssessmentInvite = assessmentAbility.can(Action.Rescore, Subject.ASSESSMENT_INVITE);
  const canViewAssessmentInviteTimer = assessmentAbility.can(Action.Read, Subject.ASSESSMENT_INVITE, "timerDisabled");
  const canUpdateAssessmentInviteTimer = assessmentAbility.can(
    Action.Update,
    Subject.ASSESSMENT_INVITE,
    "timerDisabled",
  );
  const hasTimeLimit =
    assessment &&
    assessment.assessmentTests?.some((assessmentTest) =>
      assessmentTest.test.testElements.some((testElement) => {
        const timeLimit = testElement.config.startsSection?.timeLimit;
        return timeLimit && timeLimit > 0;
      }),
    );
  const handleSearchCandidate = async (value: string) => {
    if (!assessment) return;

    onCandidateSearch(value);
  };
  const resetSearchCandidate = () => {
    onCandidateSearch("");
  };
  const [selectedInviteIds, setSelectedInviteIds] = useState<number[]>([]);
  const direction = sortOptions?.direction === "ASC" ? "ascend" : "descend";

  const candidatesInvitesColumns: ColumnsType<IAssessmentInvite> = [
    ...candidatesInvitesColumnsStatic,
    {
      key: "actions",
      title: "",
      className: cn(style.tableColumn, style.toLeft),
      render: (record: IAssessmentInvite) => {
        return (
          <div className={cn(style.actionsContainer, style.minActionsGap)}>
            {(isAdmin || canManageAssessment) && (
              <Tooltip overlay="Audit">
                <Link
                  to={`${pathname}/${record.inviteId}?${REPORT_MENU_ITEM}=audit`}
                  aria-label={`View audit for ${record.candidateFullName}`}
                >
                  <Button className={style.resendButton}>
                    <AuditSVG />
                  </Button>
                </Link>
              </Tooltip>
            )}

            {(isAdmin ||
              canManageAssessment ||
              canManageAssessmentInvite ||
              canViewAssessmentInviteTimer) && (
              <Dropdown
                trigger={["click"]}
                placement="bottomRight"
                overlay={
                  <Menu>
                    {hasTimeLimit && !record.timerDisabled && canUpdateAssessmentInviteTimer && (
                      <Menu.Item
                        key="disable-timer"
                        disabled={
                          ![AssessmentInviteStatus.Invited, AssessmentInviteStatus.Accepted].includes(record.status)
                        }
                        onClick={() => onInvitesDisableTimer([record])}
                      >
                        Disable Timer
                      </Menu.Item>
                    )}
                    <Menu.Item
                      key="rescind"
                      disabled={[AssessmentInviteStatus.Done, AssessmentInviteStatus.Rescinded].includes(record.status)}
                      onClick={() => onInvitesRescind([record.inviteId])}
                    >
                      Rescind
                    </Menu.Item>
                    {canRescoreAssessmentInvite && !isAssessmentArchived && (
                      <Menu.Item
                        key="rescore"
                        disabled={record.status !== AssessmentInviteStatus.Done}
                        onClick={() => onInvitesRescore([record.inviteId])}
                      >
                        Rescore
                      </Menu.Item>
                    )}
                    {hasTimeLimit && record.timerDisabled && canViewAssessmentInviteTimer && (
                      <Menu.Item key="timer-disabled" disabled={true}>
                        Timer Disabled
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <MoreOutlined style={{ fontSize: 24 }} />
              </Dropdown>
            )}

            <Link to={`${pathname}/${record.inviteId}`}>
              <Button
                className={style.resendButton}
                disabled={record.status !== AssessmentInviteStatus.Done}
                aria-label={`View report for ${record.candidateFullName}`}
              >
                <Row className={style.centred}>
                  <div
                    className={cn(style.buttonNameContainer, {
                      [style.disabledLink]: record.status !== AssessmentInviteStatus.Done,
                    })}
                  >
                    Report
                  </div>
                </Row>
              </Button>
            </Link>
          </div>
        );
      },
    },
  ];

  candidatesInvitesColumns[0].filterDropdown = ({ confirm, selectedKeys, setSelectedKeys }) => {
    return (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          autoFocus
          placeholder="Search by candidate"
          value={selectedKeys[0] ?? ""}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => {
            confirm();
            handleSearchCandidate((selectedKeys[0] as string) ?? "");
          }}
          style={{ marginBottom: 8, display: "block" }}
        />

        <Space>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              confirm();
              handleSearchCandidate((selectedKeys[0] as string) ?? "");
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, background: "#253955", borderColor: "#253955" }}
          >
            Search
          </Button>

          <Button
            onClick={() => {
              resetSearchCandidate();
              setSelectedKeys([]);
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    );
  };
  candidatesInvitesColumns[0].defaultSortOrder =
    sortOptions?.column === "name" && sortOptions?.direction ? direction : undefined;
  candidatesInvitesColumns[0].defaultFilteredValue = [searchParams.get("candidate")].filter(Boolean) as string[];
  candidatesInvitesColumns[1].defaultSortOrder =
    sortOptions?.column === "email" && sortOptions?.direction ? direction : undefined;
  candidatesInvitesColumns[2].filters = statuses.map((status) => ({
    text: statusText[status],
    value: status,
  }));
  candidatesInvitesColumns[2].defaultFilteredValue = filteredStatuses.filter(Boolean);
  candidatesInvitesColumns[2].defaultSortOrder =
    sortOptions?.column === "status" && sortOptions?.direction ? direction : undefined;
  candidatesInvitesColumns[3].defaultSortOrder =
    sortOptions?.column === "createdAt" && sortOptions?.direction ? direction : undefined;
  candidatesInvitesColumns[4].defaultSortOrder =
    sortOptions?.column === "claimedOn" && sortOptions?.direction ? direction : undefined;
  candidatesInvitesColumns[5].defaultSortOrder =
    sortOptions?.column === "startedOn" && sortOptions?.direction ? direction : undefined;
  candidatesInvitesColumns[6].defaultSortOrder =
    sortOptions?.column === "finishedOn" && sortOptions?.direction ? direction : undefined;

  const isBulkRescindAvailable = selectedInviteIds
    .map((id) => dataList.find(({ inviteId }) => id === inviteId))
    .filter(
      (invite: IAssessmentInvite | undefined) =>
        invite && ![AssessmentInviteStatus.Rescinded, AssessmentInviteStatus.Done].includes(invite.status),
    ).length;

  const isBulkRescoreAvailable = selectedInviteIds.some((id) => {
    const invite = dataList.find(({ inviteId }) => id === inviteId);
    return invite && invite.status === AssessmentInviteStatus.Done;
  });

  return (
    <div className={style.tableBlock}>
      <TableHeader tableName={"Candidates"} handleSearchChange={(e) => onSearchChange(e)} hasSearchField={false}>
        <div className={style.tableButtonsBlock}>
          <CustomButton
            disabled={isAssessmentArchived || !isSendable || !canManageAssessmentInvite}
            title="Show QR"
            onClick={() => openQRModal && openQRModal()}
            colorType={ButtonColorType.transparentDark}
            buttonSize={ButtonSize.small}
          />
          <CustomButton
            title="Add Manually"
            onClick={onAddCandidates}
            colorType={ButtonColorType.dark}
            buttonSize={ButtonSize.small}
            disabled={isAssessmentArchived || !isSendable || !canManageAssessmentInvite}
          >
            <PlusSVG aria-hidden="true" />
          </CustomButton>

          <Dropdown
            placement="bottomRight"
            trigger={["click"]}
            disabled={isAssessmentArchived}
            overlay={
              <Menu
                items={[
                  {
                    key: 0,
                    disabled: !(
                      (isAdmin || canManageAssessment || canManageAssessmentInvite) &&
                      isBulkRescindAvailable
                    ),
                    onClick: () => {
                      onInvitesRescind(selectedInviteIds);
                      setSelectedInviteIds([]);
                    },
                    label: "Rescind",
                  },
                  canRescoreAssessmentInvite && !isAssessmentArchived
                    ? {
                        key: "rescore",
                        disabled: !isBulkRescoreAvailable,
                        onClick: () => {
                          onInvitesRescore(selectedInviteIds);
                          setSelectedInviteIds([]);
                        },
                        label: "Rescore",
                      }
                    : null,
                ].filter(Boolean)}
              />
            }
          >
            <CustomButton title="Bulk Actions" colorType={ButtonColorType.dark} buttonSize={ButtonSize.small} />
          </Dropdown>
        </div>
      </TableHeader>

      <Table
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedInviteIds,
          onChange: (selectedRowKeys, selectedRows: IAssessmentInvite[]) =>
            setSelectedInviteIds(selectedRows.map(({ inviteId }) => inviteId)),
        }}
        columns={candidatesInvitesColumns}
        dataSource={dataList}
        style={{ width: "100%" }}
        loading={loading}
        scroll={{ x: "1200px" }}
        onChange={(pagination, filters, sorter: any) => {
          const direction =
            typeof sorter.order === "undefined" ? sorter.order : sorter.order === "ascend" ? "ASC" : "DESC";
          const column = sorter.columnKey === "candidateFullName" ? "name" : sorter.columnKey;

          onSortChange({ direction, column });
          onFilterStatus((filters.status as string[]) || []);
        }}
        pagination={{
          position: ["bottomLeft"],
          pageSize: pageSize,
          total: totalCount,
          showSizeChanger: true,
          current: currentPage,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
          },
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
        }}
      />
    </div>
  );
}
