import { AbilityBuilder, CreateAbility, PureAbility, createMongoAbility } from "@casl/ability";

import { TDbUser } from "@/shared/hooks/auth/currentUser.hook";

import { ClientRoleEnum } from "@/modules/client/client-roles.interface";
import { AssessmentRoleEnum } from "@/modules/assessments/services/assessment-permissions-http.service";
import { IAssessment } from "@/modules/assessments/assessments.types";

export enum Subject {
  ALL = "all",
  ASSESSMENT = "assessment",
  ASSESSMENT_INVITE = "assessment_invite",
  ASSESSMENT_USER_PERMISSION = "assessment_user_permission",
  CLIENT = "client",
  CLIENT_USER = "client_user",
  CLIENT_TEXT = "client_text",
  REPORT_SETTING = "report_setting",
  USER_SETTING = "user_setting",
  USER_NOTIFICATION_SETTING = "user_notification_setting",
}

export enum Action {
  Manage = "manage",
  Create = "create",
  Read = "read",
  Update = "update",
  Delete = "delete",
  Rescore = "rescore",
}

export type AppAbility = PureAbility<[Action, Subject]>;

export class CaslAbilityFactory {
  private readonly abilityBuilder: AbilityBuilder<PureAbility<[Action, Subject]>>;

  public constructor() {
    this.abilityBuilder = new AbilityBuilder(createMongoAbility as CreateAbility<AppAbility>);
  }

  public buildForUser() {
    return this.abilityBuilder.build();
  }

  public createForClientUser(clientRoles: ClientRoleEnum[], user?: Pick<TDbUser, "isAdmin">) {
    const { can, cannot } = this.abilityBuilder;

    clientRoles.forEach((roleName) => {
      if (roleName === ClientRoleEnum.ADMIN) {
        can(Action.Manage, Subject.ASSESSMENT_INVITE);
        can(Action.Read, Subject.ASSESSMENT);
        can(Action.Manage, Subject.ASSESSMENT_USER_PERMISSION);
        can(Action.Read, Subject.CLIENT);
        can(Action.Manage, Subject.CLIENT_USER);
        can(Action.Update, Subject.CLIENT, ["logoUrl"]);
        can(Action.Read, Subject.USER_NOTIFICATION_SETTING);
        can(Action.Update, Subject.USER_NOTIFICATION_SETTING);
        can(Action.Read, Subject.USER_SETTING);

        cannot(Action.Rescore, Subject.ASSESSMENT_INVITE);
        cannot(Action.Read, Subject.ASSESSMENT_INVITE, ["timerDisabled"]);
        cannot(Action.Update, Subject.ASSESSMENT_INVITE, ["timerDisabled"]);
        cannot(Action.Delete, Subject.CLIENT_USER);
      }

      if (roleName === ClientRoleEnum.USER) {
        can(Action.Read, Subject.ASSESSMENT);
      }
    });

    if (user) {
      this.createForUser(user);
    }

    return this.buildForUser();
  }

  public createForAssessmentUser(
    assessmentRoles: AssessmentRoleEnum[],
    assessment?: Pick<IAssessment, "createdBy">,
    user?: Pick<TDbUser, "isAdmin">,
  ) {
    const { can, cannot } = this.abilityBuilder;

    assessmentRoles.forEach((roleName) => {
      if (roleName === AssessmentRoleEnum.ASSESSMENT_INVITER) {
        can(Action.Manage, Subject.ASSESSMENT_INVITE);
        can(Action.Read, Subject.ASSESSMENT);

        cannot(Action.Rescore, Subject.ASSESSMENT_INVITE);
      }

      if (roleName === AssessmentRoleEnum.USER) {
        can(Action.Read, Subject.ASSESSMENT);
      }
    });

    if (user) {
      this.createForUser(user);
    }

    return this.buildForUser();
  }

  private createForUser(user: Pick<TDbUser, "isAdmin">) {
    const { can, cannot } = this.abilityBuilder;

    if (user.isAdmin) {
      can(Action.Manage, Subject.ALL); // read-write access to everything
      cannot(Action.Read, Subject.USER_NOTIFICATION_SETTING);
      cannot(Action.Update, Subject.USER_NOTIFICATION_SETTING);
      cannot(Action.Read, Subject.USER_SETTING);
    }

    return this.buildForUser();
  }
}
