import React, { ChangeEvent, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { notification, Popover, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table/Table";
import { IClientUserTable } from "@/modules/admin/clientManagement/ClientTeam/ClientTeam";
import { IClient } from "@/modules/client/clients.interface";
import { ClientHttpService } from "@/modules/client/services/client-http.service";
import TableHeader from "@/shared/components/TableHeader/TableHeader";
import { sortElements } from "@/shared/utils/array/sort.utils";
import { isValidNumericParam, makeReadableName } from "@/shared/utils/common.utils";
import { isSubstring } from "@/shared/utils/comparison/comparison";
import { formatTableData } from "@/shared/utils/formaters/uiFormaters/tableFormaters";
import { longFormatDate, shortFormatDate } from "@/shared/utils/formaters/dataFormater/formatDate";
import { selectClientRoles } from "@/shared/services/store/rolesStore";
import { Action, Subject } from "@/shared/services/permissions/casl-ability.factory";
import { IClientUserRole } from "@/shared/services/roles/roles-http.service";
import { ClientAbilityContext } from "@/shared/contexts/ability.context";
import { ReactComponent as PlusSVG } from "@/assets/icons/feather-icons/plus-16-white.svg";
import { ButtonColorType, ButtonSize, CustomButton } from "@/ui-components/commonComponents/Button/Button";
import { useRemoveClientUser } from "@/shared/hooks/users/use-remove-client-user.hook";
import { UserContext } from "@/shared/contexts/user.context";
import { DeleteOutlined } from "@ant-design/icons";

import style from "./style.module.css";

function getClientUserColumns(
  clientRoles: IClientUserRole[],
  canUpdate: boolean,
  clientId: number | undefined,
): ColumnsType<IClientUserTable> {
  return [
    {
      key: "name",
      title: "Full Name",
      dataIndex: "name",
      className: style.tableColumnName,
      defaultSortOrder: "ascend",
      sorter: (a: any, b: any) => {
        return sortElements(a.name, b.name);
      },
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      className: style.tableColumn,
      defaultSortOrder: "ascend",
      sorter: (a: any, b: any) => {
        return sortElements(a.email, b.email);
      },
    },
    {
      key: "role",
      title: "Role",
      dataIndex: "roleId",
      className: style.tableColumn,
      defaultSortOrder: "ascend",
      render: (roleId: any, record: any) => {
        const userRole = clientRoles.find((role) => roleId && role.roleId === roleId);

        if (canUpdate) {
          return (
            <Select
              showSearch
              placeholder="Select a role"
              options={clientRoles.map(({ name, roleId }) => ({
                value: roleId,
                label: makeReadableName(name),
              }))}
              optionFilterProp="children"
              filterOption={(input, option) => isSubstring(String(option?.label), input)}
              onSelect={(value: string) => handleSelectRole(value, record.key, clientId)}
              defaultValue={userRole ? makeReadableName(userRole.name) : ""}
            />
          );
        } else {
          return userRole ? makeReadableName(userRole.name) : "";
        }
      },
    },
    {
      key: "registerDate",
      title: "Register Date",
      dataIndex: "registerDate",
      render: (value: any) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
      className: style.tableColumn,
    },
    {
      key: "joinDate",
      title: "Join Date",
      dataIndex: "joinDate",
      render: (value: any) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
      className: style.tableColumn,
    },
    {
      key: "lastLogin",
      title: "Last Login Date",
      dataIndex: "lastLogin",
      render: (value: any) => formatTableData(value, shortFormatDate, longFormatDate, "-"),
      className: style.tableColumn,
    },
  ];
}

const handleSelectRole = async (selectedRoleId: string, userId: number, clientId: number | undefined) => {
  try {
    if (
      clientId &&
      isValidNumericParam(clientId) &&
      isValidNumericParam(userId) &&
      isValidNumericParam(selectedRoleId)
    ) {
      await ClientHttpService.updateClientUserRole(clientId, userId, {
        roleId: Number(selectedRoleId),
      });
      notification.success({ message: `User role was successfully updated.` });
    }
  } catch (e: any) {
    notification.error({ message: e.message });
  }
};

export default function TableClientUsers({
  dataList,
  client,
  onInviteUser,
}: {
  dataList: IClientUserTable[];
  client: IClient | undefined;
  onInviteUser: () => void;
}) {
  const DEFAULT_PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [tableData, setTableData] = useState<IClientUserTable[]>(dataList);
  const { loading: loadingRemove, removeClientUser } = useRemoveClientUser();
  const { user } = useContext(UserContext);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTableData(dataList.filter((item: IClientUserTable) => isSubstring(item.name, event.target.value)));
  };
  const clientRoles = useSelector(selectClientRoles);

  const ability = useContext(ClientAbilityContext);
  const canCreateClientUser = ability.can(Action.Create, Subject.CLIENT_USER);
  const canUpdateClientUser = ability.can(Action.Update, Subject.CLIENT_USER);
  const canDeleteClientUser = ability.can(Action.Delete, Subject.CLIENT_USER);

  const columns = useMemo(() => {
    let clientUserColumns = getClientUserColumns(clientRoles, canUpdateClientUser, client?.clientId);

    if (canDeleteClientUser) {
      clientUserColumns.push({
        key: "userId",
        title: "",
        dataIndex: "userId",
        className: style.tableColumn,
        render: (userId: number) =>
          user?.userId !== userId && (
            <Popover
              key={userId}
              trigger="click"
              content={
                <div
                  style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
                >
                  <div style={{ marginBottom: 12 }}>
                    <p className={style.text}>Are you sure you want to remove this person?</p>
                    <p className={style.text}>This action cannot be undone.</p>
                  </div>

                  <CustomButton
                    title="Remove"
                    buttonSize={ButtonSize.small}
                    isLoading={loadingRemove}
                    onClick={async () => {
                      if (!client?.clientId) return;

                      await removeClientUser(client.clientId, userId);
                      setTableData(dataList.filter((user) => user.userId !== userId));
                    }}
                  />
                </div>
              }
            >
              <DeleteOutlined />
            </Popover>
          ),
      });
    }

    return clientUserColumns;
  }, [
    clientRoles,
    canUpdateClientUser,
    canDeleteClientUser,
    client?.clientId,
    loadingRemove,
    dataList,
    user?.userId,
    removeClientUser,
  ]);

  return (
    <div className={style.tableBlock}>
      <TableHeader tableName={`${client?.name} Team`} handleSearchChange={handleSearchChange}>
        <CustomButton
          title="Invite users"
          onClick={onInviteUser}
          disabled={!canCreateClientUser}
          colorType={ButtonColorType.dark}
          buttonSize={ButtonSize.small}
        >
          <PlusSVG aria-hidden="true" />
        </CustomButton>
      </TableHeader>
      <Table
        loading={!client}
        columns={columns}
        dataSource={tableData}
        scroll={{ x: "1000px" }}
        style={{ width: "100%" }}
        pagination={{
          position: ["bottomLeft"],
          pageSize: pageSize,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
        }}
      />
    </div>
  );
}
